<!--
 * @Author: wuruoyu
 * @Description: 首页
 * @Date: 2020-12-22 13:31:46
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-22 13:33:03
 * @FilePath: \huayun_platform\src\views\home\index.vue
-->
<template>
  <div class="home-page">
    华运慧能首页
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {

    }
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
</style>
